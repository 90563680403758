<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="展示商品"
      sub-title=""
      @back="() => $router.go(-1)"
    >
      <a-row type="flex">
      </a-row>
    </a-page-header>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

import {
  exhibition_commodity_record_list
} from '@/api/exhibition_commodity'
export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  props: {},
  data () {
    return {
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '创建时间',
          dataIndex: 'create_time'
        },
        {
          title: '操作人',
          align: 'center',
          dataIndex: 'operate_by'
        },
        {
          title: '展示数量',
          align: 'center',
          dataIndex: 'exhibition_count'
        },
        {
          title: '保税数量',
          align: 'center',
          dataIndex: 'bonded_count'
        },
        {
          title: '类型',
          align: 'center',
          customRender: (text) => this.$Dictionaries.BondedCommodityChangeCategory[text],
          dataIndex: 'category'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return exhibition_commodity_record_list(Object.assign(parameter, this.queryParam, { exhibition_commodity_id: this.$route.params.id }))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created () {

  },
  methods: {}
}
</script>
