<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="展示货物">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'exhibition_goods_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="展示货物"
                :default-active-first-option="false"
                @search="handleExhibitionGoodsSearch"
                @change="handleExhibitionGoodsChange"
              >
                <a-select-option v-for="d in dataSource_exhibition_goods" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <!--            <a-form-item label="货物">
              <a-select
                @change="handleaddGoodsChange"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'exhibition_goods_id',
                  {
                    rules: [
                      { required: false, message: '' },
                    ],
                  },
                ]"
                placeholder="输入查找"
              >
                <a-select-option :key="item.id" :value="item.id" v-for="item in this.goodsList">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>-->
          </a-col>
          <a-col :span="12">
            <a-form-item label="保税商品">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'bonded_commodity_id',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="输入查找"
              >
                <a-select-option :key="item.id" :value="item.id" v-for="item in this.batchList">{{ item.batch }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="仓库" >
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-decorator="[
                  'exhibition_warehouse_id',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="输入查找"
              >
                <a-select-option :key="item.id" :value="item.id" v-for="item in this.warehouseList">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="成本价">
              <a-input v-decorator="['cost', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="吊牌价">
              <a-input v-decorator="['tag_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="排序">
              <a-input placeholder="请输入数字，数字小展示在前面" v-decorator="['serial', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="售价">
              <a-input v-decorator="['sales_price', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="图片" v-show="false">
              <a-textarea v-decorator="['img', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="图片">
              <div class="clearfix">
                <a-upload
                  v-decorator="[
                    'img_all',
                    {
                      valuePropName: 'img_all',
                      rules: [{required: false, message: '必填项，请填写信息'}]
                    },
                  ]"
                  name="file"
                  accept="image/png, image/jpeg, image/jpg"
                  :action="actionUrl"
                  :data="new_multipart_params"
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      Upload
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="图片错误" style="width: 100%" :src="previewImage || ''" />
                </a-modal>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { exhibition_goods_list } from '@/api/exhibition_goods'
import { exhibition_warehouse_list } from '@/api/exhibition_warehouse'
import { bonded_commodity_list } from '@/api/bonded_commodity'
import { oss_token_list } from '@/api/oss_token'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
// 表单字段
const fields = ['id', 'cost', 'serial', 'tag_price', 'sales_price']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      dataSource_exhibition_goods: [],
      model: {},
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      ImgUrl: '',
      fileList: [],
      batchList: [],
      goodsList: [],
      warehouseList: [],
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    this.fileList = []

    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.getExhibitionWarehouse()
  },
  methods: {
    handleExhibitionGoodsSearch (value) {
      exhibition_goods_list({ name: value }).then((res) => {
        let result = res.data.entries || []
        this.dataSource_exhibition_goods = !value ? [] : result
      })
    },
    handleExhibitionGoodsChange (value) {
      console.log(value)
      let objneo = this.dataSource_exhibition_goods.find((item) => {
        return item.id === value
      })
      console.log(objneo, 'objneo', this.dataSource_exhibition_goods)
      var obj = Object.assign({}, objneo)

      obj.tag_price = (obj.tag_price / 100).toFixed(2)
      obj.sales_price = (obj.sales_price / 100).toFixed(2)
      this.form.setFieldsValue({
        tag_price: obj.tag_price,
        sales_price: obj.sales_price
      })
      this.getbatchCommodity(obj.bonded_goods_id)
    },
    filterOption (input, option) {
      console.log(input, option.componentOptions.children[0].text)
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    // goods
    handleaddGoodsChange (value) {
      console.log(value)
      let obj = this.goodsList.find((item) => {
        return item.id === value
      })
      obj.tag_price = (obj.tag_price / 100).toFixed(2)
      obj.sales_price = (obj.sales_price / 100).toFixed(2)
      this.model = obj
      this.getbatchCommodity(obj.bonded_goods_id)
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ img: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ img: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    getExhibitionWarehouse () {
      exhibition_warehouse_list().then(({ data }) => {
        console.log(data)
        this.warehouseList = data.entries || []
      })
    },
    getbatchCommodity (bonded_goods_id) {
      bonded_commodity_list({ bonded_goods_id: bonded_goods_id }).then(({ data }) => {
        console.log(data)
        this.batchList = data.entries || []
        this.form.setFieldsValue({
          bonded_commodity_id: ''
        })
      })
    }
  }
}
</script>
